import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components}>
    <h1 {...{
      "id": "nextjs"
    }}>{`Next.js`}</h1>
    <p>{`Next.js provides an `}<a parentName="p" {...{
        "href": "https://github.com/zeit/next-plugins/tree/master/packages/next-mdx"
      }}>{`official plugin`}</a>{` to simplify MDX importing
into your project.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`npm install --save-dev @zeit/next-mdx
`}</code></pre>
    <p>{`To configure MDX, add the following to your `}<inlineCode parentName="p">{`next.config.js`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const withMDX = require('@zeit/next-mdx')()

module.exports = withMDX()
`}</code></pre>
    <h3 {...{
      "id": "use-mdx-for-md-files"
    }}>{`Use MDX for `}<inlineCode parentName="h3">{`.md`}</inlineCode>{` files`}</h3>
    <p>{`The Next.js MDX plugin allows for you to also use MDX parsing for `}<inlineCode parentName="p">{`.md`}</inlineCode>{` files:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const withMDX = require('@zeit/next-mdx')({
  extension: /\\.mdx?$/
})

module.exports = withMDX({
  pageExtensions: ['js', 'jsx', 'md', 'mdx']
})
`}</code></pre>

    </MDXLayout>;
}
MDXContent.isMDXComponent = true;
      